<template>
  <base-section
    id="start-trial"
    space="56"
  >
    <a name="free-trial-license"></a>
    <base-section-heading
      color="grey lighten-2"
      :title="$t('trialTitle')"
    >
    </base-section-heading>

    <v-container>
      <v-row justify="space-between">
        <!--<v-col-->
          <!--cols="12"-->
          <!--md="5"-->
        <!--&gt;-->
          <!--<base-info-card-->
            <!--:title="$t('licenseLicense')"-->
            <!--space="4"-->
            <!--color="primary"-->
          <!--/>-->
          <!--<v-card-->
            <!--elevation="10"-->
            <!--class="mb-12"-->
          <!--&gt;-->
            <!--<base-img-->
              <!--max-width="100%"-->
              <!--src="screenshots/olapdb_license.png"-->
            <!--/>-->
          <!--</v-card>-->
        <!--</v-col>-->

        <v-col
          cols="12"
          md="5"
        >
          <base-business-info>
          </base-business-info>
          <!--<base-info-card-->
            <!--:title="$t('licenseLicense')"-->
            <!--space="4"-->
            <!--color="primary"-->
          <!--/>-->
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <base-license-form :title="$t('licenseTitle')" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>

  export default {
    name: 'SectionStartTrial',
  }
</script>
